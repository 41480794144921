import React, { Fragment, useState, useEffect } from 'react';
// import man from '../../../assets/images/user/user.png';
import { User, Mail, Lock, LogOut } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import {EditProfile} from '../../../constant'

const UserMenu = () => {
    const [profile, setProfile] = useState('');
    // auth0 profile
    const navigate  = useNavigate();
    const logout = ()=>{
        localStorage.removeItem('token');
        localStorage.removeItem('full_name')
        localStorage.removeItem('email')
        localStorage.removeItem('first_time_login')
        localStorage.removeItem('position')
        localStorage.removeItem('role')
        window.location.reload();
    }
    useEffect(() => {
        // setProfile(localStorage.getItem('profileURL') || man);
        setProfile((localStorage.getItem('full_name') || '...').charAt(0).toUpperCase());
    }, []);


    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="d-flex align-items-center">
                    <div className="user-initials align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" data-initials={profile}></div>
                    {/*<img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={profile} alt="header-user" />*/}
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    {/* <li><Link to={`/settings`}><User />{EditProfile}</Link></li> */}
                    <li><a onClick={()=>logout()} href="#" ><LogOut /> {"Log out"}</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;