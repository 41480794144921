import React, { Fragment, useState, useEffect } from 'react';
// import Breadcrumb from '../common/breadcrumb';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from "react-datepicker";
import seven from '../../assets/images/user/7.jpg';

import { Button, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UpdateProfile, EmailAddress } from '../../constant'
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/API';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import Select from 'react-select';


const EmployeeProfile = () => {
    const history = useNavigate();
    const pathname = window.location.pathname;

    const pathId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const id = pathId ? pathId : 1
    const [state, setState] = useState({});
    const [editState, setEdit] = useState(true);
    const [userStatus, setUserStatus] = useState(1);
    const [salutation, setSalutation] = useState("Mr");
    const [radioButton, setRadioButton] = useState(1);

    const [modal, setModal] = useState();
    const [unpaid, setUnpaidLeave] = useState(0); // The input for unpaid leave
    const [leaves, setLeaves] = useState([]); // The unpaid leave history

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const [workStatus, setWorkStatus] = useState(1);

    const [dropdownOpen3, setDropdownOpen3] = useState(false);
    const toggle3 = () => setDropdownOpen3(prevState => !prevState);

    const [selectedBank, setBank] = useState('');
    const [futureWithdrawal, setFutureWithdrawal] = useState(0);
    const [withdrawalOption, setWithdrawalOption] = useState([{ value: 1, label: "Enabled" }, { value: 0, label: "Disabled" }]);
    const [bankoptions, setBankOptions] = useState([]);

    const [startDate, setStartDate] = useState(new Date())
    const toggleModal = () => {
        setModal(!modal)
    }
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem('token')
    }

    const getBankList = async (e) => {
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.get('/employerUser/getBanks',
            { headers }).then(async response => {
                if (response.data.response_code == 1000) {
                    const dropdownOptions = response.data.data.map((item) => ({
                        value: item.bank_id,
                        label: item.bank_name,
                    }));
                    setBankOptions(dropdownOptions)
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
    }

    const getUnpaidList = async () => {
        // Fetch unpaid leave history and update the leaves state
        API.post('/user/unpaidLeave', {
            "user_uuid": state.uuid,
            "days": 0
        }, { headers }).then(async response => {
            if (response.data.code === 1000) {
                const leaveData = response.data.data;

                // Sort the leaves to get the latest month
                const sortedLeaves = leaveData.sort((a, b) => b.unpaid_month - a.unpaid_month);

                // Set the latest unpaid leave days to the form
                if (sortedLeaves.length > 0) {
                    setUnpaidLeave(sortedLeaves[0].total_days); // Set the latest unpaid leave days
                }

                setLeaves(sortedLeaves); // Update the leaves history
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 });
            }
        });
    };
    const handleSelectChange = (selectedOption) => {
        setBank(selectedOption);
    };
    const handleSelectChangeWithdraw = (selectedOption) => {
        setFutureWithdrawal(selectedOption);
    };


    useEffect(() => {
        API.post('/dashboard/specific/employee',
            {
                "employee_id": id
            }
            , { headers }).then(async response => {
                if (response.data.response_code == 1000) {
                    console.log(response.data.data)
                    setState(response.data.data)
                    setWorkStatus(response.data.data.status)
                    setUserStatus(response.data.data.status)
                    if (response.data.data.withdrawal_status==1||response.data.data.withdrawal_status=='1'){
                        setFutureWithdrawal({ value: 1, label: "Enabled" })
                    }else{
                        setFutureWithdrawal({ value: 0, label: "Disabled" })
                    }
                   
                    setBank({ label: response.data.data.bank_account_name, value: response.data.data.bank_id })
                    setSalutation(response.data.data.salutation)
                    if (response.data.data.identification_number !== null) {
                        setRadioButton(1)
                    } else {
                        setRadioButton(2)
                    }
                    setStartDate(new Date(moment.unix(response.data.data.start_date)))
                    //toast.success(`${response.data.message}`, { autoClose: 5000 })
                    //history.push(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
            .catch(error => {
                if (error.response) {
                    toast.error(`Error ${error.name}: ${error.code} ${error.message}`, { autoClose: 5000 })
                } else if (error.request) {
                    toast.error('No response received from server', { autoClose: 5000 })
                } else {
                    toast.error(`Error: ${error.message}`, { autoClose: 5000 })
                }
            })
        getBankList()

    }, [id]);
    useEffect(() => {
        getUnpaidList()
    }, [state.uuid])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!state.full_name) {
            document.getElementById('full_name').classList.add('border-danger')
            return;
        }
        document.getElementById('full_name').classList.remove('border-danger')
        if (state.salary < 10) {
            document.getElementById('salary').classList.add('border-danger')
            return;
        }
        document.getElementById('salary').classList.remove('border-danger')
        if (!state.identification_number && !state.passport_number) {
            if (!state.identification_number) {
                document.getElementById('identification_number').classList.add('border-danger')
            }
            // if (!emailPattern.test(state.passport_number)) {
            //     document.getElementById('passport_number').classList.add('border-danger')
            // }
            // return;
        }
        document.getElementById('identification_number').classList.remove('border-danger')
        // document.getElementById('passport_number').classList.remove('border-danger')
        // if (!state.bank_account_name) {
        // document.getElementById('bank_name').classList.add('border-danger')
        // return;
        // }
        // document.getElementById('bank_name').classList.remove('border-danger')
        if (!state.bank_account_no) {
            document.getElementById('bank_account_no').classList.add('border-danger')
            return;
        }
        document.getElementById('bank_account_no').classList.remove('border-danger')

        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once this profile has been updated, it will be reflected in real time",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                API.post('/dashboard/update/employee',
                    {
                        "uuid": state.uuid,
                        "employee_id": state.employee_id,
                        "role": state.role !== undefined ? state.role : 0,
                        "position": state.position,
                        "salutation": salutation,
                        "full_name": state.full_name,
                        "identification_number": state.identification_number,
                        "phone_no": state.contact_no,
                        "email": state.email,
                        "start_date": startDate,
                        "confirmed": workStatus,
                        "salary": state.salary,
                        "bank_account_no": state.bank_account_no,
                        "bank_account_name": selectedBank.label,
                        "bank_id": selectedBank.value,
                        "withdrawal_status": futureWithdrawal.value,
                        "withdrawal_limit": state.withdrawal_limit,
                        "future_withdrawal_amount":state.future_withdrawal_amount
                    }
                    , { headers }).then(async response => {
                        if (response.data.response_code == 1000) {
                            toggleModal();
                            SweetAlert.fire(
                                'Updated!',
                                'Profile has been updated',
                                'success'
                            )
                            //toast.success(`${response.data.message}`, { autoClose: 5000 })
                            history(`/employee`);
                        } else if (response.data.response_code == 2000) {
                            toast.error(`${response.data.message}`, { autoClose: 5000 })
                        } else {
                            toast.error(`${response.data.message}`, { autoClose: 5000 })
                        }
                    })
            }
            else {
                // SweetAlert.fire(
                //     'Something wrong with updating profile, please try again'
                // )
            }
        })

    }
    const updateUser = async () => {
        //event.preventDefault();
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.post('/user/updateStatus',
            {
                "account_status": userStatus,
                "user_uuid": state.uuid,
                "reason": state.reason

            }
            , { headers }).then(async response => {
                if (response.data.response_code == 1000) {
                    //toggle2()
                    toast.success(`${response.data.message}`, { autoClose: 5000 })
                    history(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
    }
    const updateUnpaidLeave = async () => {
        //event.preventDefault();
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.post('/user/setUnpaidLeave',
            {
                "days": unpaid,
                "user_uuid": state.uuid,

            }
            , { headers }).then(async response => {
                if (response.data.response_code == 1000) {
                    //toggle2()
                    toast.success(`${response.data.message}`, { autoClose: 5000 })
                    //history(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
    }

    const onChange = (field, event) => {
        let fieldValue = event.target.value
        setState(state => ({
            ...state,
            [field]: fieldValue
        }))
    }
    return (
        <Fragment>
            {/*<Breadcrumb title="Profile" parent="Employee Profile" />*/}
            <ToastContainer bodyClassName={"text-white"} />
            <div className="container-fluid">
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Update User Status</ModalHeader>
                    <ModalBody>
                        <div className='form-group'>
                            <label htmlFor="status">Account Status</label>
                            <Dropdown className="" isOpen={dropdownOpen3} toggle={toggle3}>
                                <DropdownToggle className="border" color="default" caret>
                                    {userStatus == 0 ? "Deleted" : userStatus == 1 ? "Activated" : userStatus == 2 ? "De-activated" : userStatus == 3 ? "Suspended" : null}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setUserStatus(3)}>Suspended</DropdownItem>
                                    <DropdownItem onClick={() => setUserStatus(2)}>De-activate</DropdownItem>
                                    <DropdownItem onClick={() => setUserStatus(0)}>Delete</DropdownItem>
                                    <DropdownItem onClick={() => setUserStatus(1)}>Activate</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className='form-group'>
                            <label htmlFor="reason">Reason</label>
                            <input className="form-control" id="reasoning" type="text" value={state.reason} onChange={(e) => onChange('reason', e)} placeholder="Enter reason" />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => updateUser()}>Update</Button>
                        <Button color="danger" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Profile</h4>
                                    <span className="pull-right">
                                        <Button color="primary" onClick={toggleModal}>Update Account Status</Button>
                                    </span>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>

                                <div className="card-body">
                                    <form>
                                        <div className="row mb-2">
                                            <div className="col-auto"><img className="img-70 rounded-circle" alt="" src={seven} /></div>
                                            <div className="col">
                                                <h3 className="mb-1">{state.full_name}</h3>
                                                <p className="mb-4">{state.position}</p>
                                            </div>
                                        </div>
                                        <div className='row mb-4'>
                                            <div className='form-group'>
                                                <div className="input-group">
                                                    <div className="">
                                                        <label htmlFor="salutation">Salutation</label>
                                                        <Dropdown className="border" isOpen={dropdownOpen} toggle={toggle}>
                                                            <DropdownToggle disabled={editState} className="form-control border" color="default" caret>
                                                                {salutation}
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => setSalutation("Mr")} >Mr</DropdownItem>
                                                                <DropdownItem onClick={() => setSalutation("Mrs")}>Mrs</DropdownItem>
                                                                <DropdownItem onClick={() => setSalutation("Ms")}>Ms</DropdownItem>
                                                                <DropdownItem onClick={() => setSalutation("Dr")}>Dr</DropdownItem>
                                                                <DropdownItem onClick={() => setSalutation("Dato")}>Dato</DropdownItem>
                                                                <DropdownItem onClick={() => setSalutation("Datuk")}>Datuk</DropdownItem>
                                                                <DropdownItem onClick={() => setSalutation("Tan Sri")}>Tan Sri</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label htmlFor="full_name">Full Name as per NRIC:<span className="font-danger">{"*"}</span></label>
                                                            <input disabled={editState} className="form-control" id="full_name" type="text" value={state.full_name} onChange={(e) => onChange('full_name', e)} placeholder="Enter your full name" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row form-group m-t-15 m-b-15 custom-radio-ml">
                                            <div className="col-6 radio radio-primary">
                                                <input disabled={editState} id="radio1" type="radio" name="radio1" value={1} onChange={(e) => setRadioButton(e.target.value)} defaultChecked />
                                                <label disabled={editState} htmlFor="radio1">MyKad</label>
                                            </div>
                                            <div className="col-6 radio radio-primary">
                                                <input id="radio2" type="radio" name="radio1" value={2} onChange={(e) => setRadioButton(e.target.value)} />
                                                <label disabled={editState} htmlFor="radio2">Passport Number</label>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                {radioButton == 1 ? <div className="form-group">
                                                    <label htmlFor="identification_number">Identification Number:<span className="font-danger">{"*"}</span></label>
                                                    <input disabled={editState} className="form-control" id="identification_number" type="text" value={state.identification_number} onChange={(e) => onChange('identification_number', e)} placeholder="Identification number" />
                                                </div> : null}

                                                {radioButton == 2 ? <div className="form-group">
                                                    <label htmlFor="passport_number">Passport Number:</label>
                                                    <input disabled={editState} className="form-control" id="passport_number" type="text" value={state.passport_number} onChange={(e) => onChange('passport_number', e)} placeholder="Passport number" />
                                                </div> : null}
                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="form-label">Employee ID</label>
                                            <input disabled={editState} className="form-control" value={state.employee_id} onChange={(e) => onChange('employee_id', e)} placeholder="employee id" />
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Profile</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                    <span className="pull-right">
                                        <Button color="primary" onClick={() => setEdit(!editState)}>{editState == true ? 'Edit' : 'DONE'}</Button>
                                    </span>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor='contact_no' className="form-label">Contact No</label>
                                                <input disabled={editState} id='contact_no' className="form-control" type="text" value={state.contact_no} onChange={(e) => onChange('contact_no', e)} placeholder="Insert Contact No" />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 ">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input disabled={editState} className="form-control" value={state.email} onChange={(e) => onChange('email', e)} placeholder="your-email@domain.com" />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group mb-3">
                                                <label className="form-label">Position</label>
                                                <input disabled={editState} className="form-control" type="text" value={state.position} onChange={(e) => onChange('position', e)} placeholder="Position in the company" />
                                            </div>
                                        </div>
                                        {/* <div className="col-4">
                                            <label htmlFor="salutation">Work Status</label>
                                            <Dropdown disabled={true} className="" isOpen={dropdownOpen2} toggle={toggle2}>
                                                <DropdownToggle disabled={true} className="form-control border" color="default" caret>
                                                    {workStatus === 4 ? "Probation" : workStatus === 1 ? "Confirmed": workStatus===5?"Resigned" : null}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => setWorkStatus(1)}>Confirmed</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div> */}

                                        <div className='row mt-1'>
                                            <div className="col-sm-6 col-md-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Monthly Nett Salary (MYR)</label>
                                                    <input disabled={true} id='salary' className="form-control" type="text" value={state.salary} onChange={(e) => onChange('salary', e)} placeholder="RM5,000" />
                                                </div>

                                            </div>
                                            <div className="col-sm-6 col-md-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Withdrawal Limit (MYR)</label>
                                                    <input disabled={editState} id='withdrawal_limit' className="form-control" type="text" value={state.withdrawal_limit} onChange={(e) => onChange('withdrawal_limit', e)} placeholder="RM1000" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Future Withdrawal Amount (MYR)</label>
                                                    <input disabled={editState} id='future_withdrawal_amount' className="form-control" type="text" value={state.future_withdrawal_amount} onChange={(e) => onChange('future_withdrawal_amount', e)} placeholder="" />
                                                </div>
                                            </div>
                                            <div className="col-7">
                                                <div className="col-12 form-group mb-3">
                                                    <label className="form-label">Future Withdrawal</label>
                                                    <Select isDisabled={editState}
                                                        value={futureWithdrawal}
                                                        onChange={handleSelectChangeWithdraw}
                                                        options={withdrawalOption}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mt-1'>
                                                <div className="col-7">
                                                    <div className="col-12 form-group mb-3">
                                                        <label className="form-label">Designated Bank Name</label>
                                                        <Select isDisabled={editState}
                                                            value={selectedBank}
                                                            onChange={handleSelectChange}
                                                            options={bankoptions}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col-md-4">
                                                    <div className="form-group mb-3">
                                                        <label className="form-label">Designated Bank Account No</label>
                                                        <input disabled={editState} id='bank_account_no' className="form-control" type="text" value={state.bank_account_no} onChange={(e) => onChange('bank_account_no', e)} placeholder="Bank Account No" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e)} type="submit">{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                        <div className='col-lg-12'>
                            <ToastContainer theme="dark" />
                            <>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">Update Unpaid Leave</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Unpaid Leave (Days) for the Latest Month</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    value={unpaid}
                                                    onChange={(e) => setUnpaidLeave(e.target.value)}
                                                    placeholder="Enter number of unpaid leave days"
                                                    min="0"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-end">
                                        <button className="btn btn-primary" onClick={updateUnpaidLeave} type="submit">
                                            Update
                                        </button>
                                    </div>
                                </div>

                                <div className="card mt-4">
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">Unpaid Leave History</h4>
                                    </div>
                                    <div className="card-body">
                                        {leaves.length > 0 ? (
                                            <div className="list-group">
                                                {leaves.map((leave, index) => (
                                                    <div key={index} className="list-group-item">
                                                        <strong>Month:</strong> {leave.unpaid_month}, <strong>Days:</strong> {leave.total_days}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p>No unpaid leave records available.</p>
                                        )}
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default EmployeeProfile;