import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import API from '../../services/API';

const MerchantPage = () => {
    const [txn, setTxn] = useState([]);
    const [merchant, setMerchant] = useState([]);
    const [merchantList, setMerchantList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [currentMerchantId, setCurrentMerchantId] = useState(null);
    const [actionType, setActionType] = useState("");
    const [remarks, setRemarks] = useState("");
    const [newMerchantData, setNewMerchantData] = useState({
        full_name: '',
        username: '', // Phone number
        email: '',
        school_id: '',
        bank_account_name: '',
        bank_account_no: '',
        account_type: '0',
        language: 'en',
    });
    
    const handleAction = (action, merchantDetails) => {
        if (action === "complete" || action === "reject") {
            const bankAccountLast4 = "**** "+ merchantDetails?.bank_account_no?.slice(-4) || "****";
            setCurrentMerchantId(merchantDetails);
            setActionType(action);
    
            // Set default remarks based on action
            const defaultRemark =
                action === "complete"
                    ? `Transaction marked as completed and will be transferred to your bank account ending in ${bankAccountLast4}.`
                    : "Transaction rejected due to policy reasons.";
            setRemarks(defaultRemark);
    
            setShowModal(true);
        } else if (action === "view") {
            toast.info(`Viewing details for merchant ${merchantDetails}`, { autoClose: 3000 });
        }
    };
    
    const handleSettleTxnFromMerchant = async(merchantID)=>{
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        const payload = { 
            merchant_uuid: merchantID,
        };
        try {
            const response = await API.post('/transaction/settle', payload, { headers });
            if (response.data.response_code === 1000) {
                try {
                    const response = await API.post('/merchant/startSettlement', payload, { headers });
                    if (response.data.response_code === 1000) {
                        toast.success(response.data.message,
                            { autoClose: 5000 }
                        );
                        fetchTransactions(); // Refresh the transaction data
                    } else {
                        toast.error(response.data.message, { autoClose: 5000 });
                    }
                } catch (error) {
                    toast.error("Failed to complete the action. Please try again.", { autoClose: 5000 });
                }
                fetchTransactions(); // Refresh the transaction data
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.error("Failed to complete the action. Please try again.", { autoClose: 5000 });
        }
    }
    const fetchMerchantList = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        try {
            const response = await API.get('/internal/merchants', { headers });
            if (response.data.response_code === 1000) {
                setMerchantList(response.data.data.merchants);
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch merchant list.", { autoClose: 5000 });
        }
    };

    const handleSettlementTxnSetup = async () => {
        if (!remarks.trim()) {
            toast.error("Remarks cannot be empty.", { autoClose: 3000 });
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        const status = actionType === "complete" ? 2 : 0; // Set status based on action
        const payload = { 
            merchant_uuid: currentMerchantId.merchant_uuid,
            txn_uuid:currentMerchantId.uuid,
            status,
            remarks,
        };

        try {
            const response = await API.post('/merchant/updateSettlement', payload, { headers });
            if (response.data.response_code === 1000) {
                toast.success(
                    actionType === "complete" 
                        ? "Settlement Completed!" 
                        : "Transaction Cancelled!",
                    { autoClose: 5000 }
                );
                setShowModal(false);
                setRemarks("");
                fetchTransactions(); // Refresh the transaction data
            } else {
                setShowModal(false);
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.log(error)
            toast.error("Failed to complete the action. Please try again.", { autoClose: 5000 });
        }
    };
    const handleCreateMerchant = async () => {
        const headers = { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        };
        try {
            const response = await API.post('/merchant/register', newMerchantData, { headers });
            if (response.data.response_code === 1000) {
                toast.success("Merchant created successfully!");
                setShowCreateModal(false);
                fetchMerchantList(); // Refresh list
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to create merchant.");
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMerchantData({ ...newMerchantData, [name]: value });
    };
    const [columns] = useState([
        {
            name: "ID",
            selector: (row) => row.merchant_uuid,
            sortable: true,
            center: true,
        },
        {
            name: "Merchant Name",
            selector: (row) => row.merchant_name,
            sortable: true,
            center: true,
        },
        {
            name: "Total Amount (RM)",
            selector: (row) => parseFloat(row.total_amount).toFixed(2),
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            cell: row => (
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleSettleTxnFromMerchant(row.merchant_uuid)}
                >
                    Completed
                </button>
            ),
            center: true,
        },
    ]);

    const [secondColumn] = useState([
        {
            name: "ID",
            selector: (row) => row.merchant_uuid,
            sortable: true,
            center: true,
        },
        {
            name: "Merchant Name",
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
        },
        {
            name: "Bank Name",
            selector: (row) => row.bank_account_name,
            sortable: true,
            center: true,
        },
        {
            name: "Bank Account No",
            selector: (row) => row.bank_account_no,
            sortable: true,
            center: true,
        },
        {
            name: "Total Amount (RM)",
            selector: (row) => parseFloat(row.amount).toFixed(2),
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            cell: row => (row.status=='1'?
                <select
                    className="form-select form-select-sm"
                    onChange={(e) => handleAction(e.target.value, row)}
                >
                    <option value="">Select Action</option>
                    <option value="complete">Mark as Completed</option>
                    <option value="reject">Reject</option>
                </select>:row.status=='0'?'Rejected':row.status=='2'?'Completed':null
            ),
            center: true,
        },
    ]);
    const [merchantColumns] = useState([
        {
            name: "ID",
            selector: (row) => row.uuid,
            sortable: true,
            center: true,
        },
        {
            name: "SSM Number",
            selector: (row) => row.ssm_no,
            sortable: true,
            center: true,
        },
        {
            name: "Merchant Name",
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
        },

        {
            name: "Bank Account No",
            selector: (row) => row.bank_account_no,
            sortable: true,
            center: true,
        },
        {
            name: "Bank Name",
            selector: (row) => row.bank_account_name,
            sortable: true,
            center: true,
        },

    ]);

    const fetchTransactions = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        try {
            const response = await API.get('/internal/settlementTxn', { headers });
            if (response.data.response_code === 1000) {
                setTxn(response.data.data);
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch transactions.", { autoClose: 5000 });
        }
    };

    useEffect(() => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        API.get('/internal/txnPendingSettle', { headers }).then(response => {
            if (response.data.response_code === 1000) {
                setMerchant(response.data.data);
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        });
        fetchTransactions();
        fetchMerchantList();
    }, []);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25">
                                <h5>Merchants</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={merchant}
                                        striped={true}
                                        center={true}
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25">
                                <h5>Settlements</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={secondColumn}
                                        data={txn}
                                        striped={true}
                                        center={true}
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Merchant List */}
                  
                </div>
                <div className="col-xl-12">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25">
                                <h5>Merchant List</h5>
                                <div className="row mb-3">
                    <div className="col-12 text-end">
                        <button className="btn btn-primary" onClick={() => setShowCreateModal(true)}>
                            + Create New Merchant
                        </button>
                    </div>
                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={merchantColumns}
                                    data={merchantList}
                                    striped
                                    persistTableHead
                                />
                            </div>
                        </div>
                    </div>
            </div>

            {showModal && (
                <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Remarks</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter remarks"
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                                <button className="btn btn-primary" onClick={()=>handleSettlementTxnSetup()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

{showCreateModal && (
    <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Create New Merchant</h5>
                    <button type="button" className="btn-close" onClick={() => setShowCreateModal(false)}></button>
                </div>
                <div className="modal-body">
                    <form>
                        {/* Full Name */}
                        <div className="mb-3">
                            <label className="form-label">Full Name</label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter full name"
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        {/* Mobile Phone Number */}
                        <div className="mb-3">
                            <label className="form-label">Mobile Phone Number</label>
                            <input
                                type="text"
                                name="username"
                                className="form-control"
                                placeholder="Enter phone number"
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        {/* Email */}
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        {/* School ID */}
                        <div className="mb-3">
                            <label className="form-label">School UUID</label>
                            <input
                                type="text"
                                name="school_id"
                                className="form-control"
                                placeholder="Enter School UUID"
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Bank Account Name */}
                        <div className="mb-3">
                            <label className="form-label">Bank Account Name</label>
                            <input
                                type="text"
                                name="bank_account_name"
                                className="form-control"
                                placeholder="Enter bank account name"
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Bank Account No */}
                        <div className="mb-3">
                            <label className="form-label">Bank Account Number</label>
                            <input
                                type="text"
                                name="bank_account_no"
                                className="form-control"
                                placeholder="Enter bank account number"
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Account Type */}
                        <div className="mb-3">
                            <label className="form-label">Account Type</label>
                            <select
                                name="account_type"
                                className="form-select"
                                onChange={handleInputChange}
                            >
                                <option value="0">Parents</option>
                                <option value="1">Merchant</option>
                            </select>
                        </div>

                        {/* Language */}
                        <div className="mb-3">
                            <label className="form-label">Language</label>
                            <select
                                name="language"
                                className="form-select"
                                onChange={handleInputChange}
                                defaultValue="en"
                            >
                                <option value="en">English</option>
                                <option value="bm">Bahasa Malaysia</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowCreateModal(false)}
                    >
                        Close
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={handleCreateMerchant}
                    >
                        Create Merchant
                    </button>
                </div>
            </div>
        </div>
    </div>
)}

        </Fragment>
    );
};

export default MerchantPage;
