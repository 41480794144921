import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
// import Breadcrumb from '../common/breadcrumb';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/API';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

const EmployeeUser = (props) => {
  const [state, setState] = useState({
    employment_status: 1,
    passport_number: '',
    probation_period: '',
  });
  var date = new Date();
  const [employee, setEmployee] = useState([]);
  const [modal, setModal] = useState();
  const [salutation, setSalutation] = useState('Mr');
  const [employmentStatus, setEmploymentStatus] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [format_start_date, setFormatStartDate] = useState(
    String(
      date.getFullYear() +
        '-' +
        (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
        '-' +
        1
    )
  );
  const [modalAddUser, setModal2] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [selectedBank, setBank] = useState('');

  const [bankoptions, setBankOptions] = useState([]);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle3 = () => setDropdownOpen2((prevState) => !prevState);
  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
      center: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      center: true,
    },
    {
      name: 'Phone no',
      selector: (row) => row.phone_no,
      sortable: true,
      center: true,
    },
    {
      selector: (row) => row.status,
      name: 'Status',
      sortable: true,
      center: true,
    },
    {
      selector: (row) =>
        'RM' +
        Number(row.salary)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      name: 'Wage',
      sortable: true,
      center: true,
    },
    {
      selector: (row) => (
        <Button color="danger" onClick={() => resetPassword(row.phone_no)}>
          Reset Pass
        </Button>
      ),
      name: 'Operation',
      sortable: true,
      center: true,
    },
    {
      selector: (row) => (
        <Button
          onClick={() => window.open(`/profile/${row.id}`, '_self')}
          color="primary"
        >
          Edit Profile
        </Button>
      ),
      name: 'Operation',
      sortable: true,
      center: true,
    },
  ];
  const handleStartDate = (date) => {
    var the_formatted_date =
      date.getFullYear() +
      '-' +
      (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
      '-' +
      date.getDate();
    setFormatStartDate(String(the_formatted_date));
    setStartDate(date);
  };

  const getBankList = async (e) => {
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };
    API.get('/employerUser/getBanks', { headers }).then(async (response) => {
      const values = state.values;
      if (response.data.response_code == 1000) {
        const dropdownOptions = response.data.data.map((item) => ({
          value: item.bank_id,
          label: item.bank_name,
        }));
        setBankOptions(dropdownOptions);
      } else if (response.data.response_code == 2000) {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      } else {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      }
    });
  };

  const handleSelectChange = (selectedOption) => {
    setBank(selectedOption);
  };
  useEffect(() => {
    getBankList();
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };
    API.get('/user/getEmployee', { headers })
      .then((response) => {
        if (response.data.response_code == 1000) {
          response.data.data.map((item, index) => {
            setEmployee((prevState) => [
              ...prevState,
              {
                id: item.employee_id,
                name: String(item.full_name),
                email: item.work_email,
                phone_no: item.username,
                salary: item.salary,
                tenure: item.working_tenure,
                status:
                  item.status == 1 ? (
                    <span className="badge badge-primary">Activated</span>
                  ) : (
                    <span className="badge badge-danger">Unactivated</span>
                  ),
                action: (
                  <Button color="primary" onClick={() => toggle()}>
                    <span>Edit</span>
                  </Button>
                ),
                password: <Button color="danger">Reset</Button>,
              },
            ]);
          });

          //history.push(`/dashboard`);
        } else if (response.data.response_code == 2000) {
          toast.error(`${response.data.message}`, { autoClose: 5000 });
        } else {
          toast.error(`${response.data.message}`, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(`Error ${error.name}: ${error.code} ${error.message}`, {
            autoClose: 5000,
          });
        } else if (error.request) {
          toast.error('No response received from server', { autoClose: 5000 });
        } else {
          toast.error(`Error: ${error.message}`, { autoClose: 5000 });
        }
      });
  }, []);
  const toggleModal = () => {
    setState(employee[0]);
    setModal(!modal);
  };
  const toggle2 = () => {
    setModal2(!modalAddUser);
  };
  const resetPassword = async (phone_no) => {
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };
    API.post(
      '/user/adminResetPassword',
      JSON.stringify({
        username: phone_no,
      }),
      { headers }
    ).then(async (response) => {
      const values = state.values;
      if (response.data.response_code == 1000) {
        toast.success(`${response.data.message}`, { autoClose: 5000 });
        //history.push(`/dashboard`);
      } else if (response.data.response_code == 2000) {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      } else {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      }
    });
  };
  const [color, setColor] = useState('#CCC');

  const handleClick = () => {
    setColor('#F00');
  };

  const handleSubmit = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!state.full_name) {
      document.getElementById('full_name').classList.add('border-danger');
      return;
    }
    document.getElementById('full_name').classList.remove('border-danger');
    if (!state.employee_id) {
      document.getElementById('id').classList.add('border-danger');
      return;
    }
    document.getElementById('id').classList.remove('border-danger');
    if (!/^\+60\d{1}-?\d{3,4}\s?\d{4}$/.test(state.phone_no)) {
      document.getElementById('phone-number').classList.add('border-danger');
      return;
    }
    document.getElementById('phone-number').classList.remove('border-danger');
    if (state.salary < 10) {
      document.getElementById('wage').classList.add('border-danger');
      return;
    }
    document.getElementById('wage').classList.remove('border-danger');

    if (!emailPattern.test(state.work_email)) {
      document.getElementById('work_email').classList.add('border-danger');
      return;
    }
    document.getElementById('work_email').classList.remove('border-danger');

    // if (!emailPattern.test(state.email)) {
    //     document.getElementById('email').classList.add('border-danger')
    //     return;
    // }
    // document.getElementById('email').classList.remove('border-danger')

    if (!state.ic_number && !state.passport_number) {
      if (!state.ic_number) {
        document.getElementById('ic_number').classList.add('border-danger');
      }
      return;
    }

    document.getElementById('ic_number').classList.remove('border-danger');
    // document.getElementById('passport_no').classList.remove('border-danger')
    if (!selectedBank.value) {
      setColor('red');
      return;
    }
    setColor('');
    if (!state.bank_account_no) {
      document.getElementById('bank_account_no').classList.add('border-danger');
      return;
    }
    document
      .getElementById('bank_account_no')
      .classList.remove('border-danger');
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };
    const pureDigits = state.phone_no.replace(/[^\d]/g, '');
    API.post(
      '/user/register',
      {
        username: pureDigits,
        email: state.email,
        work_email: state.work_email,
        full_name: state.full_name,
        company_id: state.company_id,
        ic_number: state.ic_number ? state.ic_number : '',
        passport_number: state.passport_number ? state.passport_number : '',
        salary: state.salary,
        start_date: "",
        working_tenure: state.working_tenure ? state.working_tenure : '',
        employee_id: state.employee_id,
        probation_period: state.probation_period,
        confirm_status: employmentStatus, // todo: this should prob default to'Unconfirmed'
        position: state.position,
        salutation: salutation,
        bank_account_no: state.bank_account_no,
        bank_account_name: selectedBank.label,
        bank_id: selectedBank.value,
      },
      { headers }
    ).then(async (response) => {
      const values = state.values;
      if (response.data.response_code == 1000) {
        toggle2();
        toast.success(`${response.data.message}`, { autoClose: 5000 });
        window.location.reload();
        //history.push(`/dashboard`);
      } else if (response.data.response_code == 2000) {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      } else {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      }
    });
  };
  const onChange = (field, event) => {
    let fieldValue = event.target.value;
    setState((state) => ({
      ...state,
      [field]: fieldValue,
    }));
  };
  return (
    <Fragment>
      {/*<Breadcrumb parent="Dashboard" title="Employee User" />*/}
      <div className="container-fluid">
        <div className="row">
          <ToastContainer theme="dark" />
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Edit</ModalHeader>
            <ModalBody>
              <div className="form-group">
                <label className="col-form-label" htmlFor="name">
                  Update Name
                </label>
                <input
                  className="form-control"
                  id="name"
                  type="text"
                  placeholder="Name"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="email">
                  Update Email
                </label>
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  placeholder="em@email.com"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="wage">
                  Update Wages
                </label>
                <input
                  className="form-control"
                  id="wage"
                  type="number"
                  placeholder="Wages"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger">Deactivate Account</Button>
              <Button color="primary">Save Changes</Button>
              <Button color="light" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={modalAddUser} toggle={toggle2}>
            <ModalHeader toggle={toggle2}>
              Add New Employee
              <br></br>
              <text style={{ textAlign: 'left', color: 'gray', fontSize: 14 }}>
                Required fields *
              </text>
            </ModalHeader>
            <ModalBody>
              <form className="theme-form">
                <div className="row">
                  <div className="form-group row">
                    <div className="form-group col-2">
                      <label className="col-form-label" htmlFor="salutation">
                        Salutation
                      </label>
                      <Dropdown
                        className=""
                        isOpen={dropdownOpen}
                        toggle={toggle}
                      >
                        <DropdownToggle color="default" caret>
                          {salutation}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setSalutation('Mr')}>
                            Mr
                          </DropdownItem>
                          <DropdownItem onClick={() => setSalutation('Mrs')}>
                            Mrs
                          </DropdownItem>
                          <DropdownItem onClick={() => setSalutation('Ms')}>
                            Ms
                          </DropdownItem>
                          <DropdownItem onClick={() => setSalutation('Dr')}>
                            Dr
                          </DropdownItem>
                          <DropdownItem onClick={() => setSalutation('Dato')}>
                            Dato
                          </DropdownItem>
                          <DropdownItem onClick={() => setSalutation('Datuk')}>
                            Datuk
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => setSalutation('Tan Sri')}
                          >
                            Tan Sri
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="full_name">
                          Full Name (As per NRIC/Passport) *
                        </label>
                        <input
                          className="form-control"
                          id="full_name"
                          type="text"
                          value={state.full_name}
                          onChange={(e) => onChange('full_name', e)}
                          placeholder="John Smith"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="id">
                          Employee ID *
                        </label>
                        <input
                          className="form-control"
                          id="id"
                          type="text"
                          value={state.employee_id}
                          onChange={(e) => onChange('employee_id', e)}
                          placeholder="Employee Id"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="col-form-label"
                          htmlFor="phone-number"
                        >
                          Phone Number (please use full format +60) *
                        </label>
                        <input
                          className="form-control"
                          id="phone-number"
                          type="text"
                          value={state.phone_no}
                          onChange={(e) => onChange('phone_no', e)}
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="positon">
                        Position
                      </label>
                      <input
                        className="form-control"
                        id="positon"
                        type="text"
                        value={state.position}
                        onChange={(e) => onChange('position', e)}
                        placeholder="Manager"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="wage">
                        Wage (Monthly Gross Salary) *
                      </label>
                      <input
                        className="form-control"
                        id="wage"
                        type="number"
                        value={state.salary}
                        onChange={(e) => onChange('salary', e)}
                        placeholder="RM5,000"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="work_email">
                        Company Email
                      </label>
                      <input
                        className="form-control"
                        id="work_email"
                        type="email"
                        value={state.work_email}
                        onChange={(e) => onChange('work_email', e)}
                        placeholder="email@email.com"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="email">
                        Personal Email
                      </label>
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        value={state.email}
                        onChange={(e) => onChange('email', e)}
                        placeholder="email@email.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label style={{ display: 'flex', justifyContent: 'center' }}>
                    Official ID (Either IC or Passport No.)*
                  </label>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="ic_number">
                        IC Number
                      </label>
                      <input
                        className="form-control"
                        id="ic_number"
                        type="number"
                        value={state.ic_number}
                        onChange={(e) => onChange('ic_number', e)}
                        placeholder="Please key in IC number."
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="passport_no">
                        Passport No
                      </label>
                      <input
                        className="form-control"
                        id="passport_no"
                        type="text"
                        value={state.passport_number}
                        onChange={(e) => onChange('passport_number', e)}
                        placeholder="Passport Number"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="ic_number">
                        Bank name *
                      </label>
                      <Select
                        id="bank_name"
                        value={selectedBank}
                        onChange={handleSelectChange}
                        options={bankoptions}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: color,
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="bank_account_no"
                      >
                        Bank Account *
                      </label>
                      <input
                        className="form-control"
                        id="bank_account_no"
                        type="text"
                        value={state.bank_account_no}
                        onChange={(e) => onChange('bank_account_no', e)}
                        placeholder="Account no."
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-3">
                    <label className="col-form-label">
                      Employment Status *
                    </label>
                    <Dropdown
                      className="form-control col-3"
                      isOpen={dropdownOpen2}
                      toggle={toggle3}
                    >
                      <DropdownToggle color="default" caret>
                        {employmentStatus === 1
                          ? 'Confirm'
                          : employmentStatus === 4
                          ? 'Unconfirm'
                          : null}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => setEmploymentStatus(1)}>
                          Confirm
                        </DropdownItem>
                        <DropdownItem onClick={(e) => setEmploymentStatus(4)}>
                          Unconfirm
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  {/* <div className="col-4">
                    <label className="col-form-label">Start Date *</label>
                    <div className="input-group">
                      <DatePicker
                        className="form-control digits"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </div>
                  </div> */}
                  {employmentStatus == 4 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="tenure">
                          Probation Period (Months)
                        </label>
                        <input
                          className="form-control"
                          id="tenure"
                          type="number"
                          value={state.probation_period}
                          onChange={(e) => onChange('probation_period', e)}
                          placeholder="3"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <ModalFooter>
                  <Button color="success" onClick={() => handleSubmit()}>
                    Create Account
                  </Button>
                  <Button color="danger" onClick={toggle2}>
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </ModalBody>
          </Modal>
          <div className="col-xl-12 xl-100">
            <div className="row">
              <div className="col-xl-12 xl-100">
                <div className="card">
                  <div className="card-header">
                    <Button
                      onClick={() => toggle2()}
                      color="primary"
                      className="ml-2"
                    >
                      Add New Employee
                    </Button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={employee}
                        striped={true}
                        center={true}
                        persistTableHead
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmployeeUser;
