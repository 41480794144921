import React, { useState } from 'react';
import logo from '../assets/images/TapCash.png';
// import { Password } from '../constant';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import API from '../services/API';
const FirstTimeLoginPage = () => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        email: localStorage.getItem('email'),
        old_password: "",
        password: ""
    });

    const handleSubmit = (event) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+=[\]{};':"\\|,.<>/?]{8,}$/;
        if (state.password.length < 8) {
            toast.error("Password is too short", {autoClose: 2000})
            return;
        }
        if (!passwordPattern.test(state.password)) {
            toast.error("Password should contain at least one uppercase letter, one lowercase letter and one digit", {autoClose: 2000})
            return;
        }
        if (state.old_password === state.password) {
            toast.error("Passwords cannot be the same", {autoClose: 2000})
            return;
        }
        if (state.email !== "") {
            API.post('/employerUser/verifyAccount',
                {
                    email: state.email,
                    old_password: state.old_password,
                    password: state.password
                }
            ).then(response => {
                if (response.data.response_code == 1000) {
                    console.log(response)
                    localStorage.setItem('first_time_login', response.data.data.first_time_login)
                    navigate(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            }).catch(error => {
                toast.error(`${error.message}`)
        })
        } else {
            toast.error("Invalid email, please login again")
        }
    }

    const onChange = (field, event) => {
        let fieldValue = event.target.value
        setState(state => ({
            ...state,
            [field]: fieldValue
        }))
    }
    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <img src={logo} alt="" style={{ width: '300px', height: 'auto' }}/>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Update your password</h4>
                                                    <h6>{"Enter your new password"} </h6>
                                                </div>
                                                <form className="theme-form">
                                                <div className="form-group">
                                                        <label className="col-form-label">{"Enter your previous password"}</label>
                                                        <input className="form-control" type="password" value={state.old_password} onChange={(e) => onChange('old_password', e)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{"Enter your new password"}</label>
                                                        <input className="form-control" type="password" value={state.password} onChange={(e) => onChange('password', e)} />
                                                    </div>
                                                    <div className='row'>
                                                        <text id="too-short" className='text'>- At least 8 characters</text>
                                                        <text id="no-uppercase" className='text'>- At least one uppercase letter</text>
                                                        <text id="no-lowercase" className='text'>- At least one lowercase letter</text>
                                                        <text id="no-digits" className='text'>- At least one digit</text>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn btn-primary" type="button" onClick={() => handleSubmit()} >Update Password</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer theme="dark" />
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default FirstTimeLoginPage;