import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import API from '../../services/API';

const EditPortalUserModal = props => {
    const navigate = useNavigate();
    const [editState, setEdit] = useState(true);
    const [state, setState] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);
    const toggleOpenModal = () => {
        setIsOpenModal(!isOpenModal)
    }
    const [userRole, setRole] = useState("Sub Admin");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle3 = () => setDropdownOpen(prevState => !prevState);

    useEffect(() => {
    }, [])
    useEffect(() => {
        // props.openModal = {open:true}
        if(props.openModal.open) {
            setIsOpenModal(props.openModal.open)
        } 
    }, [props.openModal])
    useEffect(() => {
        if(props.data) {
           setState(props.data)
           setEdit(true)
        } 
    }, [props.data])

    const handleSubmit = async () => {
        let updateJSON = {...state}
        
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(state.work_email)) {
            document.getElementById('work_email').classList.add('border-danger')
            return;
        }
        document.getElementById('work_email').classList.remove('border-danger')
        if (state.phone_no.length < 9) {
            document.getElementById('phone_no').classList.add('border-danger')
            return;
        }
        document.getElementById('phone_no').classList.remove('border-danger')
        
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once this profile has been updated there is no changes allowed",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                var headers = {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'token': localStorage.getItem('token')
                }
                API.post('/employerUser/update',
                    updateJSON
                    ,{headers}
                ).then(async response => {
                    const values = state.values
                    if (response.data.response_code == 1000) {
                        // toggle2()
                        // toast.success(`${response.data.message}`, { autoClose: 5000 })
                        props.onSubmitSuccess("")
                        setIsOpenModal(false)
                        SweetAlert.fire(
                            'Updated!',
                            'User has been updated',
                            'success'
                        )
                    } else if (response.data.response_code == 2000) {
                        toast.error(`${response.data.message}`, { autoClose: 5000 })
                    } else {
                        toast.error(`${response.data.message}`, { autoClose: 5000 })
                    }
                })
                .catch(error => {
                    if (error.response) {
                        toast.error(`Error ${error.name}: ${error.code} ${error.message}`, { autoClose: 5000 })
                        navigate('/portalUser')
                    } else if (error.request) {
                        toast.error('No response received from server', { autoClose: 5000 })
                    } else {
                        toast.error(`Error: ${error.message}`, { autoClose: 5000 })
                    }
                })
            }
            else {
                // SweetAlert.fire(
                //     'Something wrong with updating profile, please try again'
                // )
            }
        })

       
    }
    const onChange = (field, event) => {
        let fieldValue = event.target.value
        setState(state => ({
            ...state,
            [field]: fieldValue
        }))
    }
    return (
        <Modal isOpen={isOpenModal} toggle={toggleOpenModal}>
            <ModalHeader toggle={toggleOpenModal}>
                Edit Portal User
                <br></br>
            </ModalHeader>
            <ModalBody>       
                <Button color='success' className='m-6' onClick={()=>setEdit(!editState)}>{editState==true?'Edit':'DONE'}</Button>
                <form className="">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="full_name">Full Name *</label>
                                <input className="form-control" id="full_name" type="text" value={state.full_name} onChange={(e) => onChange('full_name', e)}  disabled={editState} />
                            </div>

                        
                        </div>
                        <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label" htmlFor="role">Role *</label>
                                    <Dropdown className="" isOpen={dropdownOpen} toggle={toggle3}  >
                                        <DropdownToggle color="default" caret disabled={editState} >
                                            {state.role_name}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => setRole("Admin")}>Admin</DropdownItem>
                                            <DropdownItem onClick={() => setRole("Sub Admin")}>Sub Admin</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>                                            
                                </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="work_email">Email *</label>
                                <input className="form-control" id="work_email" type="text" value={state.work_email} onChange={(e) => onChange('work_email', e)}  disabled={editState} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="phone_no">Phone Number</label>
                                <input className="form-control" id="phone_no" type="text" value={state.phone_no} onChange={(e) => onChange('phone_no', e)}   disabled={editState} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="email">IC Number</label>
                                <input className="form-control" id="ic_number" type="text" value={state.ic_number} onChange={(e) => onChange('ic_number', e)}   disabled={editState} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="position">Position</label>
                                <input className="form-control" id="position" type="text" value={state.position} onChange={(e) => onChange('position', e)}   disabled={editState} />
                            </div>
                        </div>
                    </div>
                </form>
                <div className='pt-4'>
                    <ModalFooter >
                        <Button color='success' className='m-6' onClick={() => handleSubmit()}>Update Account</Button>
                        <Button color="danger" className='m-6' onClick={toggleOpenModal}>Cancel</Button>
                    </ModalFooter>
                </div>
            </ModalBody>

        </Modal>

    );
}

export default EditPortalUserModal;