import React, { useState, Fragment, useEffect } from 'react';
import API from '../../services/API';
// import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import Select from 'react-select';
const Settings = props => {
    const [newEmail, setNewEmail] = useState();
    const [newEmailConfirm, setNewEmailConfirm] = useState();
    const [emailDisabled, setEmailDisabled] = useState();
    const [verificationCode, setVerificationCode] = useState("");
    const [newEmailSubmitDisabled, setNewEmailSubmitDisabled] = useState(true);
    const [verificationDisabled, setVerificationDisabled] = useState(true);
    const [getCodeButtonDisabled, setGetCodeButtonDisabled] = useState(true);
    const [newPassword, setNewPassword] = useState();
    const [newPasswordConfirm, setNewPasswordConfirm] = useState();
    const currentEmail = localStorage.getItem('email');
    const [state, setState] = useState({});
    const [selectedBank, setBank] = useState('');
    const [bankoptions, setBankOptions] = useState([]);

    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem('token')
    }



    const handleUpdateCompany = async () => {

        API.post('/employerUser/updateCompany',
            {
                "company_name": state.company_name,
                "ssm_no": state.ssm_no,
                "email": state.email,
                "contact_name": state.contact_name,
                "contact_phone": state.contact_phone,
                "address": state.address,
                "bank_id": selectedBank.value,
                "company_bank": selectedBank.label,
                "company_bank_holder": state.company_bank_holder,
                "company_bank_acc": state.company_bank_acc
            },
            { headers }
        ).then(async response => {
            if (response.data.response_code == 1000) {
                toast.success(`${response.data.message}`, { autoClose: 5000 })
                window.location.reload();
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
            .catch(error => {
                if (error.response) {
                    toast.error(`Error ${error.name}: ${error.code} ${error.message}`, { autoClose: 5000 })
                } else if (error.request) {
                    toast.error('No response received from server', { autoClose: 5000 })
                } else {
                    toast.error(`Error: ${error.message}`, { autoClose: 5000 })
                }
            })
    }

    const getBankList = async (e) => {
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.get('/employerUser/getBanks',
            { headers }).then(async response => {
                const values = state.values
                if (response.data.response_code == 1000) {
                    const dropdownOptions = response.data.data.map((item) => ({
                        value: item.bank_id,
                        label: item.bank_name,
                    }));
                    setBankOptions(dropdownOptions)
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
    }


    const handleSelectChange = (selectedOption) => {
        setBank(selectedOption);
    };

    const getCompanyInfo = async () => {

        API.get('/employerUser/getCompany',
            { headers }).then(async response => {
                if (response.data.response_code == 1000) {
                    setState(response.data.data)
                    setBank({ label: response.data.data.company_bank, value: response.data.data.company_id })
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
            .catch(error => {
                if (error.response) {
                    toast.error(`Error ${error.name}: ${error.code} ${error.message}`, { autoClose: 5000 })
                } else if (error.request) {
                    toast.error('No response received from server', { autoClose: 5000 })
                } else {
                    toast.error(`Error: ${error.message}`, { autoClose: 5000 })
                }
            })

    }

    const handleNewPasswordSubmit = async () => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+=[\]{};':"\\|,.<>/?]{8,}$/;

        if (newPassword.length < 8) {
            toast.error("Password is too short", { autoClose: 2000 })
            return;
        }
        if (!passwordPattern.test(newPassword)) {
            toast.error("Password should contain at least one uppercase letter, one lowercase letter and one digit", { autoClose: 2000 })
            return;
        }
        if (newPasswordConfirm !== newPassword) {
            toast.error("Passwords do not match", { autoClose: 2000 })
            return;
        }
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.post('/employerUser/changePassword',
            {
                "email": currentEmail,
                "new_password": newPassword
            },
            { headers }
        ).then(async response => {
            if (response.data.response_code == 1000) {
                toast.success(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
            .catch(error => {
                if (error.response) {
                    toast.error(`Error ${error.name}: ${error.code} ${error.message}`, { autoClose: 5000 })
                } else if (error.request) {
                    toast.error('No response received from server', { autoClose: 5000 })
                } else {
                    toast.error(`Error: ${error.message}`, { autoClose: 5000 })
                }
            })
    }


    useEffect(() => {
        getBankList()
        getCompanyInfo()
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // if (!emailPattern.test(newEmail)) {
        //     document.querySelector('#newEmail-input').classList.add('border-danger')
        //     return;
        // }
        // if (newEmail === currentEmail) {
        //     document.querySelector('#newEmail-input').classList.add('border-danger')
        //     return;
        // }
        // document.querySelector('#newEmail-input').classList.remove('border-danger')
        // document.querySelector('#newEmail-input').classList.add('border-success')
        // if (newEmail != newEmailConfirm) {
        //     document.querySelector('#newEmailConfirm-input').classList.add('border-danger')
        //     return;
        // }

        // document.querySelector('#newEmailConfirm-input').classList.remove('border-danger')
        // document.querySelector('#newEmailConfirm-input').classList.add('border-success')
        setEmailDisabled(true);
        setVerificationDisabled(false);
        setGetCodeButtonDisabled(false);
        if (verificationCode) {
            if (verificationCode.length == 6) {
                setNewEmailSubmitDisabled(false);
            } else {
                setNewEmailSubmitDisabled(true)
            }
        }


    }, [newEmail, newEmailConfirm, currentEmail, verificationCode]);

    const onChange = (field, event) => {
        let fieldValue = event.target.value
        setState(state => ({
            ...state,
            [field]: fieldValue
        }))
    }

    return (
        <Fragment>
            {/*<Breadcrumb parent="Dashboard" title="Settings" />*/}
            <div className="container-fluid">
                <div className="row">
                    <div className='card'>
                        <div className='card-header' style={{ padding: '20px' }}>
                            <h5 >Company Details</h5>
                        </div>
                        <form className="form theme-form">
                            <div className="card-body">
                                <div className="mb-3 row">
                                    <label htmlFor="company_name" class="col-sm-2 col-form-label" style={{ textAlign: 'right' }}>Company Name</label>
                                    <div class="col-sm-10">
                                        <input className="form-control" id="company_name" type="text" value={state.company_name} onChange={(e) => onChange('company_name', e)} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="ssm_no" class="col-sm-2 col-form-label" style={{ textAlign: 'right' }}>Registration Number</label>
                                    <div className='col-sm-10'>
                                        <input className="form-control" id="ssm_no" type="text" value={state.ssm_no} onChange={(e) => onChange('ssm_no', e)} />
                                    </div>
                                </div>
                                <div className=" mb-3 row">
                                    <label htmlFor="email" class="col-sm-2 col-form-label" style={{ textAlign: 'right' }}>Company Email</label>
                                    <div className='col-sm-10'>
                                        <input className="form-control" id="email" type="email" value={state.email} onChange={(e) => onChange('email', e)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <label htmlFor="contact_name" class="col-sm-2 col-form-label" style={{ textAlign: 'right' }}>Contact Person</label>
                                    <div className='col-sm-3'>
                                        <input className="form-control" id="contact_name" type="text" value={state.contact_name} onChange={(e) => onChange('contact_name', e)} />
                                    </div>
                                    <label htmlFor="contact_phone" class="col-sm-2 col-form-label" style={{ textAlign: 'right' }}>Contact Number</label>
                                    <div className='col-sm-3'>
                                        <input className="form-control" id="contact_phone" type="text" value={state.contact_phone} onChange={(e) => onChange('contact_phone', e)} />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="card-body">
                                {/* <div className="mb-3 row">
                                    <label htmlFor="company_bank" class="col-sm-2 col-form-label" style={{textAlign:'right'}}>Bank</label>
                                    <div className='col-sm-10'>
                                    <Select id="bank_name"
                                                value={selectedBank}
                                                onChange={handleSelectChange}
                                                options={bankoptions}
                                            />
                                    </div>
                                </div> */}
                                {/* <div className="mb-3 row">
                                    <label htmlFor="company_bank_holder" class="col-sm-2 col-form-label" style={{textAlign:'right'}}>Account Holder Name</label>
                                    <div className='col-sm-2'>
                                        <input className="form-control" id="company_bank_holder" type="text" value={state.company_bank_holder}  onChange={(e) => onChange('company_bank_holder', e)}/>
                                    </div>
                                    <label htmlFor="company_bank_acc" class="col-sm-2 col-form-label" style={{textAlign:'right'}}>Bank Account</label>
                                    <div className='col-sm-2'> 
                                       <input className="form-control" id="company_bank_acc" type="text" value={state.company_bank_acc}  onChange={(e) => onChange('company_bank_acc', e)}/>
                                    </div>
                          
                                </div> */}

                                {/* <div className="mb-3 row">
                                    <label htmlFor="epf_number" class="col-sm-2 col-form-label" style={{textAlign:'right'}}>EPF Number</label>
                                    <div className='col-sm-10'>
                                        <input className="form-control" id="epf_number" type="text" value={state.epf_number}  onChange={(e) => onChange('epf_number', e)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <label htmlFor="socso_number" class="col-sm-2 col-form-label" style={{textAlign:'right'}}>Socso Number</label>
                                    <div className='col-sm-10'>
                                        <input className="form-control" id="socso_number" type="text" value={state.socso_number}  onChange={(e) => onChange('socso_number', e)} />
                                    </div>
                                </div> */}
                            </div>
                            <div className='card-header' style={{ padding: '10px' }}>
                                <h5 >Scheme Calculation *Not Changeable</h5>
                            </div>
                            <div className='card-body'>

                                <div className="mb-3 row">
                                    <label htmlFor="pay_start_cycle" class="col-sm-2 col-form-label" style={{ textAlign: 'right' }}>First Day of withdrawal</label>
                                    <div className='col-sm-4'>
                                        <input className="form-control" id="pay_start_cycle" type="text" value={state.pay_start_cycle} disabled />
                                    </div>
                                    <label htmlFor="pay_end_cycle" class="col-sm-2 col-form-label" style={{ textAlign: 'right' }}>Last Day of withdrawal</label>
                                    <div className='col-sm-4'>
                                        <input className="form-control" id="pay_end_cycle" type="text" value={state.pay_end_cycle} disabled />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="company_withdraw_limit" class="col-sm-2 col-form-label" style={{ textAlign: 'right' }}>Company’s Max Disbursement (Ongoing)</label>
                                    <div className='col-sm-10'>
                                        <input className="form-control" id="company_withdraw_limit" type="text" value={state.company_withdraw_limit} disabled />
                                    </div>
                                </div>
                                {/* <div className="mb-3 row">
                                    <label htmlFor="employee_withdraw_limit" class="col-sm-2 col-form-label" style={{textAlign:'right'}}>Monthly Employee Disbursement Limit</label>
                                    <div className='col-sm-10'>
                                        <input className="form-control" id="employee_withdraw_limit" type="text" value={state.employee_withdraw_limit}  disabled />
                                    </div>
                                </div> */}
                            </div>
                            <div className="card-footer text-end">
                                <button className="btn btn-primary me-2" onClick={() => handleUpdateCompany()} type="button">Update</button>
                            </div>
                        </form>
                    </div>
                    <div className='card'>
                        <div className='card-header'>
                            <h4 htmlFor="exampleFormControlInput1">Password</h4>
                        </div>
                        <form className="form theme-form">
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword">Update Password</label>
                                            <input onChange={(e) => setNewPassword(e.target.value)} className="form-control" id="exampleInputPassword2" type="password" placeholder="Password" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword2">Confirm Password</label>
                                            <input onChange={(e) => setNewPasswordConfirm(e.target.value)} className="form-control" id="exampleInputPassword2" type="password" placeholder="Password" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <text id="too-short" className='text'>- At least 8 characters</text>
                                    <text id="no-uppercase" className='text'>- At least one uppercase letter</text>
                                    <text id="no-lowercase" className='text'>- At least one lowercase letter</text>
                                    <text id="no-digits" className='text'>- At least one digit</text>
                                </div>
                                <div className="card-footer text-end">
                                    <button className="btn btn-primary me-2" onClick={() => handleNewPasswordSubmit()} type="button">Update</button>
                                    <input className="btn btn-light" type="reset" defaultValue="Cancel" />
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Settings;