import React, { useState, Fragment, useEffect } from 'react';
// import Breadcrumb from '../common/breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/API';
import DatePicker from "react-datepicker";
import { supportDB } from '../../data/support-ticket';
import DataTable from 'react-data-table-component';
import { Button, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Col, Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import moment from 'moment';


const Notifications = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [state, setState] = useState([]);
    const [historyState, setHistory] = useState([]);
    const [modal, setModal] = useState();
    const [theSelectedItem, setSelectedItem] = useState();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    var date = new Date();
    const [extendedDate, setExtendedDate] = useState(new Date());
    const [format_extend_date, setFormatExtendDate] = useState(String(date.getFullYear() + "-" + (date.getMonth() < 12 ? date.getMonth() + 1 : 1) + "-" + 1));
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [userStatus, setUserStatus] = useState(1);
    const handleChange = date => {
        var the_formatted_date = (date.getFullYear() + "-" + (date.getMonth() < 12 ? date.getMonth() + 1 : 1) + "-" + date.getDate());
        setFormatExtendDate(String(the_formatted_date))
        setExtendedDate(date)
    };
    const toggleModal = (item) => {
        setModal(!modal)
        setSelectedItem(item)
    }
    const columns = [
        {
            name: "Date",
            selector: (a) => moment.unix(a.created_time).format('DD/MM/YYYY'),
            sortable: true,
            center: true,

        },
        {
            name: "Request",
            selector: (a) => a.action_type == 1 ? `Has ${a.full_name}, Employee ID: ${a.employee_id} passed probation?` : a.action_type == 2 ? `${a.details}, please make the payment soonest to avoid disruption of service.` : null,
            sortable: true,
            center: true,
        },
        {
            selector: (item) => <div>
                <Button onClick={() => handleSubmit(item)} color="success" style={{ margin: 5 }}>Yes</Button>
                <Button onClick={() => toggleModal(item)} color="danger">No</Button>
            </div>,
            name: "",
            sortable: true,
            center: true,
        },
    ];
    const historyColumn = [
        {
            name: "Description",
            selector: (a) => a.action_type == 1 ? `${a.full_name}, Employee ID: ${a.employee_id}` : null,
            sortable: true,
            center: true,
        },
        {
            name: "Status",
            selector: (a) => a.action_type == 1 && a.status == 0 ? "Extended Probation" : "Confirmed",
            sortable: true,
            center: true,
        },
        {
            name: "Action by",
            selector: (a) => a.portal_admin_update,
            sortable: true,
            center: true,
        },
        {
            name: "Action date",
            selector: (a) => moment.unix(a.updated_time).format('DD/MM/YYYY'),
            sortable: true,
            center: true,
        },
    ];
    const handleSubmit = (item) => {
        if (item.action_type == 1) {
            SweetAlert.fire({
                title: 'Are you sure?',
                text: "Confirmed employee has passed probation",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                var headers = {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'token': localStorage.getItem('token')
                }
                API.post('/notification/update',
                    {
                        "action_uuid": item.uuid,
                        "new_date": "",
                        "employee_id": item.employee_id,
                        "type": 2
                    }
                    , { headers }).then(async response => {
                        if (response.data.response_code == 1000) {
                            //console.log(done)
                        } else if (response.data.response_code == 2000) {
                            toast.error(`${response.data.message}`, { autoClose: 5000 })
                        } else {
                            toast.error(`${response.data.message}`, { autoClose: 5000 })
                        }
                    })
            })
        } else if (item.action_type == 2) {
            var headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'token': localStorage.getItem('token')
            }
            API.post('/consolidation/update',
                {
                    "action_uuid": item.uuid
                }
                , { headers }).then(async response => {
                    if (response.data.response_code == 1000) {
                        //console.log(done)
                    } else if (response.data.response_code == 2000) {
                        toast.error(`${response.data.message}`, { autoClose: 5000 })
                    } else {
                        toast.error(`${response.data.message}`, { autoClose: 5000 })
                    }
                })
        }

    }
    const handleNoSubmission = () => {
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.post('/notification/update',
            {
                "action_uuid": theSelectedItem.uuid,
                "new_date": format_extend_date,
                "employee_id": theSelectedItem.employee_id,
                "type": 1
            }
            , { headers }).then(async response => {
                if (response.data.response_code == 1000) {
                    //console.log(done)
                    setModal(false)
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
    }
    useEffect(() => {
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.get('/notification/getAction', { headers }).then(response => {
            if (response.data.response_code == 1000) {
                setState(response.data.data)
                console.log(response.data.data)
                //history.push(`/dashboard`);
            } else if (response.data.response_code == 2000) {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                //toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
        API.get('/notification/getHistory', { headers }).then(response => {
            if (response.data.response_code == 1000) {
                setHistory(response.data.data)
                //history.push(`/dashboard`);
            } else if (response.data.response_code == 2000) {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                //toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
    }, []);
    return (
        <Fragment>
            {/*<Breadcrumb title="Actions" parent="Actions item" />*/}
            <div className='container-fluid'>
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Update User Status</ModalHeader>
                    <ModalBody>
                        <div className='form-group'>
                            <label htmlFor="status">Account Status</label>
                            <Dropdown className="" isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle className="border" color="default" caret>
                                    {userStatus == 1 ? "Unconfirmed" : userStatus == 2 ? "Extend Probation" : null}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setUserStatus(1)}>Unconfirmed</DropdownItem>
                                    <DropdownItem onClick={() => setUserStatus(2)}>Extend probation</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            {userStatus == 2 ? <div className="m-2 col-12">
                                <div className="p-2 form-group">
                                    <label className="form-label">New Probation Date</label>
                                    <DatePicker className="form-control digits" selected={extendedDate} minDate={moment().toDate()} onChange={handleChange} />
                                </div>
                            </div> : null}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => handleNoSubmission()}>Update</Button>
                        <Button color="danger" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Col lg="12 box-col-12" xl="12 xl-100">
                    <Card>
                        <CardHeader>
                            <Nav className="nav-pills nav-primary">
                                <NavItem>
                                    <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                        <i className="icofont icofont-ui-home"></i>Pending Actions
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                        <i className="icofont icofont-man-in-glasses"></i>History
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="tabbed-card">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={state}
                                            striped={true}
                                            center={true}
                                            persistTableHead
                                            pagination
                                        />
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={historyColumn}
                                            data={historyState}
                                            striped={true}
                                            center={true}
                                            persistTableHead
                                            pagination
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </Fragment>
    );
}

export default Notifications;