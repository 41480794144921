import React, { useState, Fragment } from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import Footer from './common/footer';
import { ToastContainer } from 'react-toastify';
import Loader from './common/loader';
import { Outlet } from 'react-router-dom';
import Breadcrumb from './common/breadcrumb';

const AppLayout = (props) => {
    const formatDate = (dateObj) => {
        let date = [
            (dateObj.getDate()).toString().padStart(2, '0'),
            (dateObj.getMonth() + 1).toString().padStart(2, '0'),
            dateObj.getFullYear()
        ].join('/');
        let hour = dateObj.getHours();
        let timeDefination = hour > 12 ? 'pm': 'am';
        let time = [
            hour > 12 ? hour - 12: hour,
            (dateObj.getMinutes()).toString().padStart(2, '0'),
        ].join(':');
        return date + ' ' + time + timeDefination;
    }
    const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState(formatDate(new Date()));

    return (
        <Fragment>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header />
                    <Sidebar />
                    <div className="page-body">
                        <Breadcrumb parent="Dashboard" title="Home" lastUpdatedDateTime={lastUpdatedDateTime} />
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </div>
            <ToastContainer theme="dark" />
        </Fragment>
    );
}

export default AppLayout;