import React, { useState, Fragment, useEffect } from 'react';
import EditPortalUserModal from './EditPortalUserModal';
import DataTable from 'react-data-table-component'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/API';
import SweetAlert from 'sweetalert2';

const PortalUser = props => {
    const [state, setState] = useState({
        email: "",
        password: ""
    });
    const [employee, setEmployee] = useState([]);
    const [userRole, setRole] = useState("Sub Admin");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle3 = () => setDropdownOpen(prevState => !prevState);

    const columns = [
        {
            name: "Name",
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
    
        },
        {
            selector: (row) => row.work_email,
            name: "Email",
            sortable: true,
            center: true,
        },
        {
            selector: (row) => {
                let Component = row.first_time_login == 1 ?
                                <span className='badge badge-primary'>Activated</span>
                                : <span className='badge badge-danger'>Unactivated</span>
                return Component
            },
            name: "Status",
            sortable: true,
            center: true,
        },
        {
            selector: (row) => row.role_name,
            name: "Role",
            sortable: true,
            center: true,
        },
        {
            selector: (row) => <Button onClick={()=>openEditModal(row)} color="primary">Edit Profile</Button>,
            name: "",
            sortable: true,
            center: true,
        },
    ];

    useEffect(() => {
        requestPortalUserList();
    }, [])

    const requestPortalUserList = async ()=> {
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.get('/employerUser/getEmployee', { headers }).then(response => {
            if (response.data.response_code == 1000) {
                console.log(response.data.data)
                response.data.data.map((item, index) => {
                    // setEmployee(prevState => ([
                    //     ...prevState, {
                    //         ...item,
                    //         // id: item.uuid,
                    //         // name: String(item.full_name),
                    //         // email: item.work_email,
                    //         // position: item.position,
                    //         // role: item.role_name,
                    //         // first_time_login: item.first_time_login == 1 ?
                    //         //     <span className='badge badge-primary'>Activated</span>
                    //         //     : <span className='badge badge-danger'>Unactivated</span>,
                    //         // action: <Button color='primary' onClick={() => toggle()}>
                    //         //     <span >Edit Profile</span>
                    //         // </Button>
                    //     }]))

                    setEmployee(response.data.data)
                })

                //history.push(`/dashboard`);
            } else if (response.data.response_code == 2000) {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
    }

    const handleSubmit = async () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!state.full_name) {
            document.getElementById('full_name').classList.add('border-danger')
            return;
        }
        document.getElementById('full_name').classList.remove('border-danger')
        if (!emailPattern.test(state.email)) {
            document.getElementById('email').classList.add('border-danger')
            return;
        }
        document.getElementById('email').classList.remove('border-danger')
  
        if (!state.phone_no || state.phone_no.length < 9) {
            document.getElementById('phone_no').classList.add('border-danger')
            return;
        }
        document.getElementById('phone_no').classList.remove('border-danger')
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.post('/employerUser/register',
            {
                "email": state.email,
                "phone_no": state.phone_no,
                "ic_no": state.ic_no,
                "position": state.position,
                "role": userRole === "Admin"?"1":"2",
                "full_name": state.full_name,
            }
        ,{headers}).then(async response => {
            const values = state.values
            if (response.data.response_code == 1000) {
                requestPortalUserList()
                toggle2()
                // toast.success(`${response.data.message}`, { autoClose: 5000 })
                SweetAlert.fire(
                    '',
                    'Successfully Registered',
                    'success'
                )

            } else if (response.data.response_code == 2000) {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
        .catch(error => {
            if (error.response) {
                toast.error(`Error ${error.name}: ${error.code} ${error.message}`, { autoClose: 5000 })
            } else if (error.request) {
                toast.error('No response received from server', { autoClose: 5000 })
            } else {
                toast.error(`Error: ${error.message}`, { autoClose: 5000 })
            }
          })
    }
    const [modal, setModal] = useState();
    const [modalAddUser, setModal2] = useState();
    useEffect(() => {
        if(modalAddUser == false) {
            setState({}) //reset add new portal
        }
    }, [modalAddUser])

    

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [editModalData, setEditModalData] = useState(null);

    const toggle = () => {
        setModal(!modal)
    }
    const toggle2 = () => {
        setModal2(!modalAddUser)
    }
    const onChange = (field, event) => {
        let fieldValue = event.target.value
        setState(state => ({
            ...state,
            [field]: fieldValue
        }))
    }
    const openEditModal = (row) => {
        setIsOpenEditModal({open:true})
        setEditModalData(row)
    }
    return (
        <Fragment>
            <EditPortalUserModal openModal={isOpenEditModal} data={editModalData} onSubmitSuccess={()=>{
                requestPortalUserList()
            }}/>
            <div className="container-fluid">
                <div className="row">
                    <Modal isOpen={modalAddUser} toggle={toggle2}>
                        <ModalHeader toggle={toggle2}>
                            Add New Portal User
                            <br></br>
                            <text style={{textAlign: 'left', color: 'gray', fontSize: 14}}>Required fields *</text>    
                        </ModalHeader>
                        <ModalBody>
                            <form className="theme-form">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label" htmlFor="full_name">Full Name *</label>
                                            <input className="form-control" id="full_name" type="text" value={state.full_name} onChange={(e) => onChange('full_name', e)} placeholder="John" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="role">Role *</label>
                                                <Dropdown className="" isOpen={dropdownOpen} toggle={toggle3}>
                                                    <DropdownToggle color="default" caret>
                                                        {userRole}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setRole("Admin")}>Admin</DropdownItem>
                                                        <DropdownItem onClick={() => setRole("Sub Admin")}>Sub Admin</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>                                            
                                            </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="email">Email *</label>
                                                <input className="form-control" id="email" type="text" value={state.email} onChange={(e) => onChange('email', e)} placeholder="email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="phone_no">Phone Number</label>
                                                <input className="form-control" id="phone_no" type="text" value={state.phone_no} onChange={(e) => onChange('phone_no', e)} placeholder="Phone Number" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="email">IC Number</label>
                                                <input className="form-control" id="ic_number" type="text" value={state.ic_no} onChange={(e) => onChange('ic_no', e)} placeholder="ic number" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="position">Position</label>
                                                <input className="form-control" id="position" type="text" value={state.role} onChange={(e) => onChange('position', e)} placeholder="Position" />
                                            </div>
                                        </div>
                                    </div>
                                    <ModalFooter>
                                        <Button color='success' className='m-6' onClick={() => handleSubmit()}>Create Account</Button>
                                        <Button color="danger" className='m-6' onClick={toggle2}>Cancel</Button>
                                    </ModalFooter>

                                </div>
                            </form>
                        </ModalBody>

                    </Modal>
                    <div className="col-xl-12 xl-100">
                        <div className="row">
                            <div className="col-xl-12 xl-100">
                                <div className="card">
                                    <div className=" card-header ">
                                        <Button onClick={() => toggle2()} color='primary' className='ml-2'>Add New Portal User</Button>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={employee}
                                                striped={true}
                                                center={true}
                                                persistTableHead
                                                pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer theme="dark" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default PortalUser;