export class ConfigDB {
	static data = {
	 settings: {
		 layout_type: 'ltr',
	 sidebar: {
		 type: '',
		 body_type: '' 
	 },
		 sidebar_setting: 'default-sidebar', 
		 sidebar_backround: 'dark-sidebar' 
				},
	 color: {
		 layout_version: 'light-only', 
		 color: 'color-1', 
		 primary_color: '#E94057', 
		 secondary_color: '#1ea6ec', 
		 mix_layout: 'light-only' 
	 },
	 router_animation: 'fadeIn'
	 }
	 }
export default ConfigDB;