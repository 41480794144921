import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import API from '../../services/API';

const StudentPage = () => {
    const [students, setStudents] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        total_pages: 1,
        total_students: 0,
        limit: 50
    });
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [imagesModalOpen, setImagesModalOpen] = useState(false);
    const [modalImages, setModalImages] = useState([]);
    const [augmentedImages, setAugmentedImage] = useState([]);
    const [modalLoading, setModalLoading] = useState(false); // Loading state for modal


    const fetchStudents = async (page = 1, limit = 30, search = '') => {
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        try {

            const response = await API.get(`/internal/students?page=${page}&limit=${limit}&search=${search}`, { headers });
            if (response.data.response_code === 1000) {
                const { students, pagination } = response.data.data;
                setStudents(students);
                setPagination(pagination);
            } else {
                setSearchQuery('')
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to fetch students.', { autoClose: 5000 });
        } finally {
            setLoading(false);
        }
    };
    const FileDownload = require("js-file-download");
    const downloadStudents = () => {
        var headers = {
            responseType: 'blob',
            'token': localStorage.getItem('token')
        }
        API.post("/internal/downloadStudents", {
        }, { headers }).then(({ data }) => {
            FileDownload(data, "schoolpay_download.csv");
        }).catch(err => {
            console.log("Error: ", err);
        });
    }

    const handleReRecord = async (studentId, parentId) => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        const payload = {
            student_id: studentId,
            parent_id: parentId,
        };

        try {
            const response = await API.post('/student/reRecord', payload, { headers });
            if (response.data.response_code === 1000) {
                toast.success("Re-record request sent successfully!", { autoClose: 5000 });
                fetchStudents(pagination.current_page, pagination.limit);
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to send re-record request. Please try again.", { autoClose: 5000 });
        }
    };

    useEffect(() => {
        fetchStudents();
    }, []);

    const handleViewImages = async (studentId) => {
        setModalLoading(true); // Start loading
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        try {
            const response = await API.get(`/aiFace/getAllImages/${studentId}`, { headers });
            if (response.data.response_code === 1000) {
                const images = response.data.data.images; // Assuming images are returned in this format
                const augmentedImages = response.data.data.augmentedImages
                setAugmentedImage(augmentedImages)
                setModalImages(images);
                setImagesModalOpen(true);
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.error("Failed to fetch images. Please try again.", { autoClose: 5000 });
        } finally {
            setModalLoading(false); // Stop loading
        }
    };

    const getStatusChip = (status) => {
        switch (status) {
            case '0':
                return (
                    <span
                        style={{
                            backgroundColor: '#f8d7da',
                            color: '#721c24',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Unrecorded
                    </span>
                );
            case '3':
                return (
                    <span
                        style={{
                            backgroundColor: '#fff3cd',
                            color: '#856404',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Requires Re-record
                    </span>
                );
            case '4':
                return (
                    <span
                        style={{
                            backgroundColor: '#e2e3e5',
                            color: '#383d41',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Parents not linked
                    </span>
                );
            case '2':
                return (
                    <span
                        style={{
                            backgroundColor: '#d4edda',
                            color: '#155724',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Completed
                    </span>
                );
            case '1':
                return (
                    <span
                        style={{
                            backgroundColor: '#cce5ff',
                            color: '#004085',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Training
                    </span>
                );
            default:
                return (
                    <span
                        style={{
                            backgroundColor: '#f8f9fa',
                            color: '#6c757d',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Unknown
                    </span>
                );
        }
    };

    const columns = [
        {
            name: 'Student UUID',
            selector: (row) => row.student_uuid,
            sortable: true,
            center: true,
            wrap: true
        },
        {
            name: 'Profile Picture',
            cell: (row) => (
                row.status == '0' ? null : <img
                    src={row.profile_pic}
                    alt={`${row.first_name}'s Profile`}
                    style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                />
            ),
            center: true,
        },
        {
            name: 'Full Name',
            selector: (row) => row.full_name? row.full_name:row.first_name,
            sortable: true,
            center: true,
            wrap: true
        },
        {
            name: 'School Name',
            selector: (row) => row.school_name,
            sortable: true,
            center: true,
            wrap: true
        },
        {
            name: 'Class Name',
            selector: (row) => row.class_name || 'N/A',
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status, // Default mapping to status
            cell: (row) => getStatusChip(row.status), // Use getStatusChip for dynamic styling
            sortable: true,
            center: true,
        },
        {
            name: 'Balance (RM)',
            selector: (row) => parseFloat(row.balance).toFixed(2),
            sortable: true,
            center: true,
        },
        {
            name: 'Daily Limit (RM)',
            selector: (row) => parseFloat(row.daily_limit).toFixed(2),
            sortable: true,
            center: true,
        },
        {
            name: 'View Images',
            cell: (row) => (
                <button
                    className="btn btn-info btn-sm"
                    onClick={() => handleViewImages(row.student_uuid)}
                >
                    View Images
                </button>
            ),
            center: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleReRecord(row.student_uuid, row.parent_uuid)}
                >
                    Re-record Profile
                </button>
            ),
            center: true,
        },
    ];

    // Fetch default data when searchQuery is empty
    useEffect(() => {
        if (!searchQuery.trim()) {
            fetchStudents(1, pagination.limit);
        }
    }, [searchQuery]);

    const handleSearchChange = (e) => {
        const value = e.target.value;

        setSearchQuery(value);

        // Automatically reset the data when the search query is empty
        if (!value.trim()) {
            // Reset limit to 30 and fetch default data
            const newLimit = 30;
            setPagination((prev) => ({ ...prev, limit: newLimit }));
            fetchStudents(1, newLimit);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        fetchStudents(1, pagination.limit, searchQuery.trim()); // Use trimmed query to fetch
    };

    const handlePageChange = (page) => {
        fetchStudents(page, pagination.limit, searchQuery.trim()); // Maintain search context
    };

    const handlePerPageChange = (newLimit) => {
        fetchStudents(1, newLimit, searchQuery.trim()); // Reset to first page on limit change
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25">
                                <h5>Students</h5>
                                <div className="d-flex justify-content-end mt-2">
                                    <form onSubmit={handleSearchSubmit} className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className="form-control mr-2"
                                            placeholder="Search by first name"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <button type="submit" className="btn btn-primary">Search</button>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <button
                                    className="btn btn-success mb-3"
                                    onClick={() => downloadStudents(pagination.current_page, pagination.limit, true)}
                                >
                                    Download CSV
                                </button>
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={students}
                                        striped
                                        persistTableHead
                                        progressPending={loading}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={pagination.total_students}
                                        paginationDefaultPage={pagination.current_page} // Dynamic page
                                        paginationPerPage={pagination.limit}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerPageChange}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal
                    isOpen={imagesModalOpen}
                    onRequestClose={() => setImagesModalOpen(false)}
                    contentLabel="Student Images"
                    ariaHideApp={false} // Set to false if this is not the main app
                    style={{
                        content: {
                            maxWidth: '800px',
                            margin: 'auto',
                            padding: '20px',
                            borderRadius: '10px',
                        },
                    }}
                >
                        <h2>Student Images</h2>

                        <div className="images-grid" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {modalImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Student Image ${index + 1}`}
                                    style={{ width: '200px', height: '200px', borderRadius: '5px', objectFit: 'cover' }}
                                />
                            ))}
                        </div>
                        <h2>Augmented Images</h2>
                        <div className="images-grid" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {augmentedImages ? augmentedImages.map((image, index) => (
                                <img
                                    key={index} 
                                    src={image}
                                    alt={`Student Augmented Image ${index + 1}`}
                                    style={{ width: '200px', height: '200px', borderRadius: '5px', objectFit: 'cover' }}
                                />
                            )) : null}
                        </div>
                        <button className="btn btn-secondary mt-3" onClick={() => setImagesModalOpen(false)}>Close</button>
                </Modal>
            </div>
        </Fragment>
    );
};

export default StudentPage;
